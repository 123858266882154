import { ProductModel } from '@shared/models/product.model';
import { createAction, props } from '@ngrx/store';
import { IComment } from '@shared/models/comment-model';
import { ShareCigarModel } from '@shared/models/share-cigar.model';
import { ShareReviewModel } from '@shared/models/share-review.model';
import { SocialPostModel, ISocialLike } from '@shared/models/social-post.model';
import { UserType } from '@shared/models/user.model';

export const SocialPostRequest = createAction(
  'SOCIAL_POST_LOAD_REQUEST',
  props<{
    postId: number;
  }>()
);
export const SocialPostSuccess = createAction(
  'SOCIAL_POST_LOAD_SUCCESS',
  props<{
    postId: number;
    post: SocialPostModel;
  }>()
);
export const SocialPostError = createAction(
  'SOCIAL_POST_LOAD_ERROR',
  props<{
    postId: number;
  }>()
);
export const SocialPostsRequest = createAction(
  'SOCIAL_POSTS_LOAD_REQUEST',
  props<{
    take?: number;
    skip?: number;
    followedOnly: boolean;
  }>()
);
export const SocialUserPostsRequest = createAction(
  'SOCIAL_USER_POSTS_LOAD_REQUEST',
  props<{
    take?: number;
    skip?: number;
    userId: string;
    userType: UserType;
  }>()
);
export const SocialProductPostsRequest = createAction(
  'SOCIAL_PRODUCT_POSTS_LOAD_REQUEST',
  props<{
    take?: number;
    skip?: number;
    productId: string;
  }>()
);
export const SocialPostsSuccess = createAction(
  'SOCIAL_POSTS_LOAD_SUCCESS',
  props<{
    collectionId: string;
    take: number;
    skip: number;
    posts: SocialPostModel[];
  }>()
);
export const SocialPostsError = createAction(
  'SOCIAL_POSTS_LOAD_ERROR',
  props<{ collectionId: string }>()
);

export const SocialPostsToTop = createAction(
  'SOCIAL_POSTS_TO_TOP',
  props<{ collectionId: string }>()
);

export const SocialPostsCleanup = createAction(
  'SOCIAL_POSTS_CLEANUP',
  props<{ collectionId: string }>()
);

export const SocialPostsGarbageCollection = createAction(
  'SOCIAL_POSTS_GARBAGE_COLLECTION'
);

export const SocialPostFollowToggleRequest = createAction(
  'SOCIAL_POST_FOLLOW_TOGGLE_REQUEST',
  props<{
    userType: UserType;
    userId: string;
    isFollowed: boolean;
  }>()
);
export const SocialPostFollowToggleSuccess = createAction(
  'SOCIAL_POST_FOLLOW_TOGGLE_SUCCESS',
  props<{
    userType: UserType;
    userId: string;
    isFollowed: boolean;
  }>()
);
export const SocialPostFollowToggleError = createAction(
  'SOCIAL_POST_FOLLOW_TOGGLE_ERROR',
  props<{
    userType: UserType;
    userId: string;
    isFollowed: boolean;
  }>()
);
export const SocialPostLikeToggleRequest = createAction(
  'SOCIAL_POST_LIKE_TOGGLE_REQUEST',
  props<{
    postId: number;
    isLiked: boolean;
  }>()
);
export const SocialPostLikeToggleSuccess = createAction(
  'SOCIAL_POST_LIKE_TOGGLE_SUCCESS',
  props<{
    postId: number;
    like?: ISocialLike;
  }>()
);
export const SocialPostLikeToggleError = createAction(
  'SOCIAL_POST_LIKE_TOGGLE_ERROR',
  props<{
    postId: number;
  }>()
);
export const SocialPostCreateRequest = createAction(
  'SOCIAL_POST_CREATE_REQUEST',
  props<{
    post: ShareCigarModel | ShareReviewModel | SocialPostModel | ProductModel;
  }>()
);
export const SocialPostCreateSuccess = createAction(
  'SOCIAL_POST_CREATE_SUCCESS',
  props<{
    post: SocialPostModel;
  }>()
);
export const SocialPostCreateError = createAction('SOCIAL_POST_CREATE_ERROR');
export const SocialPostEditRequest = createAction(
  'SOCIAL_POST_EDIT_REQUEST',
  props<{
    postId: number;
    post: SocialPostModel;
  }>()
);
export const SocialPostEditSuccess = createAction(
  'SOCIAL_POST_EDIT_SUCCESS',
  props<{
    postId: number;
    post: SocialPostModel;
  }>()
);
export const SocialPostEditError = createAction(
  'SOCIAL_POST_EDIT_ERROR',
  props<{
    postId: number;
  }>()
);
export const SocialPostRemoveRequest = createAction(
  'SOCIAL_POST_REMOVE_REQUEST',
  props<{
    postId: number;
  }>()
);
export const SocialPostRemoveSuccess = createAction(
  'SOCIAL_POST_REMOVE_SUCCESS',
  props<{
    postId: number;
  }>()
);
export const SocialPostRemoveError = createAction(
  'SOCIAL_POST_REMOVE_ERROR',
  props<{
    postId: number;
  }>()
);
export const SocialPostLoadCommentsRequest = createAction(
  'SOCIAL_POST_LOAD_COMMENTS_REQUEST',
  props<{
    postId: number;
  }>()
);
export const SocialPostLoadCommentsSuccess = createAction(
  'SOCIAL_POST_LOAD_COMMENTS_SUCCESS',
  props<{
    postId: number;
    comments: IComment[];
  }>()
);
export const SocialPostLoadCommentsError = createAction(
  'SOCIAL_POST_LOAD_COMMENTS_ERROR',
  props<{
    postId: number;
  }>()
);
export const SocialPostAddCommentRequest = createAction(
  'SOCIAL_POST_ADD_COMMENT_REQUEST',
  props<{
    postId: number;
    text: string;
  }>()
);
export const SocialPostAddCommentSuccess = createAction(
  'SOCIAL_POST_ADD_COMMENT_SUCCESS',
  props<{
    postId: number;
    comment: IComment;
  }>()
);
export const SocialPostAddCommentError = createAction(
  'SOCIAL_POST_ADD_COMMENT_ERROR',
  props<{
    postId: number;
  }>()
);
export const SocialPostEditCommentRequest = createAction(
  'SOCIAL_POST_EDIT_COMMENT_REQUEST',
  props<{
    postId: number;
    commentId: number;
    text: string;
  }>()
);
export const SocialPostEditCommentSuccess = createAction(
  'SOCIAL_POST_EDIT_COMMENT_SUCCESS',
  props<{
    postId: number;
    commentId: number;
    comment: IComment;
  }>()
);
export const SocialPostEditCommentError = createAction(
  'SOCIAL_POST_EDIT_COMMENT_ERROR',
  props<{
    postId: number;
    commentId: number;
  }>()
);

export const SocialPostRemoveCommentRequest = createAction(
  'SOCIAL_POST_REMOVE_COMMENT_REQUEST',
  props<{
    postId: number;
    commentId: number;
  }>()
);

export const SocialPostRemoveCommentSuccess = createAction(
  'SOCIAL_POST_REMOVE_COMMENT_SUCCESS',
  props<{
    postId?: number;
    commentId: number;
  }>()
);

export const SocialPostRemoveCommentError = createAction(
  'SOCIAL_POST_REMOVE_COMMENT_ERROR',
  props<{
    postId: number;
    commentId: number;
  }>()
);

export const SocialPostReportRequest = createAction(
  'SOCIAL_POST_REPORT_REQUEST',
  props<{
    postId: number;
    reasonId: number;
  }>()
);

export const SocialPostReportSuccess = createAction(
  'SOCIAL_POST_REPORT_SUCCESS',
  props<{
    postId: number;
    reasonId: number;
  }>()
);

export const SocialPostReportError = createAction(
  'SOCIAL_POST_REPORT_ERROR',
  props<{
    postId: number;
    reasonId: number;
  }>()
);

export const SocialPostReportUserRequest = createAction(
  'SOCIAL_POST_REPORT_USER_REQUEST',
  props<{
    userType: UserType;
    userId: string;
    reasonId: number;
  }>()
);

export const SocialPostReportUserSuccess = createAction(
  'SOCIAL_POST_REPORT_USER_SUCCESS',
  props<{
    userType: UserType;
    userId: string;
    reasonId: number;
  }>()
);

export const SocialPostReportUserError = createAction(
  'SOCIAL_POST_REPORT_USER_ERROR',
  props<{
    userType: UserType;
    userId: string;
    reasonId: number;
  }>()
);

export const SocialPostReportCommentRequest = createAction(
  'SOCIAL_POST_REPORT_COMMENT_REQUEST',
  props<{
    commentId: number;
    reasonId: number;
  }>()
);

export const SocialPostReportCommentSuccess = createAction(
  'SOCIAL_POST_REPORT_COMMENT_SUCCESS',
  props<{
    commentId: number;
    reasonId: number;
  }>()
);

export const SocialPostReportCommentError = createAction(
  'SOCIAL_POST_REPORT_COMMENT_ERROR',
  props<{
    commentId: number;
    reasonId: number;
  }>()
);

export const SocialBlockUserRequest = createAction(
  'SOCIAL_BLOCK_USER_REQUEST',
  props<{
    userType: UserType;
    userId: string;
  }>()
);

export const SocialBlockUserSuccess = createAction(
  'SOCIAL_BLOCK_USER_SUCCESS',
  props<{
    userType: UserType;
    userId: string;
  }>()
);

export const SocialBlockUserError = createAction(
  'SOCIAL_BLOCK_USER_ERROR',
  props<{
    userType: UserType;
    userId: string;
  }>()
);

export type ActionsUnion =
  | typeof SocialPostsRequest
  | typeof SocialUserPostsRequest
  | typeof SocialProductPostsRequest
  | typeof SocialPostsSuccess
  | typeof SocialPostsError
  | typeof SocialPostLikeToggleRequest
  | typeof SocialPostLikeToggleSuccess
  | typeof SocialPostLikeToggleError
  | typeof SocialPostAddCommentRequest
  | typeof SocialPostAddCommentSuccess
  | typeof SocialPostAddCommentError
  | typeof SocialPostRemoveCommentRequest
  | typeof SocialPostRemoveCommentSuccess
  | typeof SocialPostRemoveCommentError
  | typeof SocialPostEditCommentRequest
  | typeof SocialPostEditCommentSuccess
  | typeof SocialPostEditCommentError
  | typeof SocialPostReportCommentRequest
  | typeof SocialPostReportCommentSuccess
  | typeof SocialPostReportCommentError
  | typeof SocialBlockUserRequest
  | typeof SocialBlockUserSuccess
  | typeof SocialBlockUserError
  | typeof SocialPostReportRequest
  | typeof SocialPostReportSuccess
  | typeof SocialPostReportError
  | typeof SocialPostReportUserRequest
  | typeof SocialPostReportUserSuccess
  | typeof SocialPostReportUserError
  | typeof SocialPostFollowToggleRequest
  | typeof SocialPostFollowToggleSuccess
  | typeof SocialPostFollowToggleError
  | typeof SocialPostRemoveRequest
  | typeof SocialPostRemoveSuccess
  | typeof SocialPostRemoveError
  | typeof SocialPostEditRequest
  | typeof SocialPostEditSuccess
  | typeof SocialPostEditError;
